<template>
  <div>
    <div
      class="mot-a-dessiner shake"
      :style="{
        'font-size': e2_mot_font_size + 'px',
        'line-height': e2_mot_line_height + 'px',
      }"
    >{{ e2_mot }}</div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.mot-a-dessiner {
  margin-top: -6px;
  font-family: cabin_sketch_bold;
  color: #0a73b0;

  &.shake {
    animation: shake 900ms cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: 1;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}
</style>

<script>
export default {
  name: 'E2MotADessiner',

  computed: {
    e2_mot_font_size() {
      if (this.e2_mot === null || typeof this.e2_mot !== 'string') return 65;

      if (this.e2_mot.length <= 12) return 65;

      // Caillou qui roule vraiment très très vite avec des chenipans qui tuent des mamies vieilles => 78
      let taille = 65 - 65 * (this.e2_mot.length - 12) / 78;
      return taille <= 35 ? 35 : taille;
    },

    e2_mot_line_height() {
      if (this.e2_mot === null || typeof this.e2_mot !== 'string') return 60;

      if (this.e2_mot.length <= 12) return 60;

      // Caillou qui roule vraiment très très vite avec des chenipans qui tuent des mamies vieilles => 78
      let taille = 65 - 60 * (this.e2_mot.length - 12) / 78;
      return taille <= 33 ? 33 : taille;
    },
  },

  props: ['e2_mot'],
}
</script>
